import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch as MuiSwitch,
} from "@mui/material";

const Switch = ({ onChange, filter }) => {
  const { value, name, label } = filter;
  return (
    <FormControl>
      <FormGroup>
        <div>
          <FormControlLabel
            control={
              <MuiSwitch
                checked={value}
                onChange={onChange}
                name={name}
                color="primary"
              />
            }
            label={label}
            labelPlacement="start"
            sx={{ width: "100%", justifyContent: "space-between" }}
          />
        </div>
      </FormGroup>
    </FormControl>
  );
};

export default Switch;
