import { useMutation } from "react-query";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useApp } from "../../../../../AppProvider";
import { calculatePolygonArea } from "./usePolygonTool";
import { useState } from "react";

const useProcessConfiguration = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();
  const [submittedPolygonArea, setSubmittedPolygonArea] = useState(0);

  const mutation = useMutation(
    async ({ paramsArray, polygon }) => {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };

      await axios.put(
        `${process.env.REACT_APP_ENDPOINT}/api/process-configuration`,
        {
          params: paramsArray,
          polygon,
        },
        { headers }
      );

      const { data: results } = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/api/results`,
        { headers }
      );

      setSubmittedPolygonArea(
        parseFloat(calculatePolygonArea(polygon).toFixed(2))
      );

      doToast("success", "Scenario successfully processed!");
      return results;
    },
    {
      onError: (error) => {
        doToast("error", "Error running scenario:", error);
        console.error("Error running scenario:", error);
      },
    }
  );

  return { mutation, submittedPolygonArea };
};

export default useProcessConfiguration;
