import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const columns = [
  { field: "use_new", headerName: "Land Cover Type", flex: 1 },
  {
    field: "background_landuse",
    headerName: "Modeled Current Conditions",
    flex: 2,
  },
  {
    field: "scenario_landuse",
    headerName: "Selected Scenario",
    flex: 2,
  },
  { field: "acres", headerName: "Acres", flex: 1 },
];

const LandUseTable = ({ data }) => {
  return (
    <Box height={400}>
      <DataGrid
        rows={data.map((row, index) => ({ id: index, ...row }))}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
      />
    </Box>
  );
};

export default LandUseTable;
