import { useEffect, useState } from "react";
import useFetchData from "../../../../hooks/useFetchData";

// Fetch a list of sources to add to the map
const useSources = () => {
  const [sources, setSources] = useState([]);

  const { data, error, isLoading } = useFetchData({
    endpoint: "public-map/sources",
    queryOptions: {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  });

  useEffect(() => {
    if (!!data?.length) {
      setSources(data);
    }
  }, [data]);

  return { error, isLoading, sources, setSources };
};

export default useSources;
