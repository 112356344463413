import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Paper,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import bbox from "@turf/bbox";
import { polygon as turfPolygon } from "@turf/helpers";

import usePolygonTool from "../hooks/usePolygonTool";
import useParameterInputs from "../hooks/useParameterInputs";
import useQueryParams from "../hooks/useQueryParams";
import useSavedConfigurations from "../hooks/useSavedConfigurations";
import useBreakpoints from "../../../../../hooks/useBreakpoints";

import ParameterInputs from "./ParameterInputs";
import PolygonControls from "./PolygonControls";
import SavedConfigurationsList from "./SavedConfigurationsList";
import { DEFAULT_PARAMETERS_INPUT_VALUES } from "../constants";
import { styled } from "@mui/material/styles";

const Container = styled(Paper)(({ width }) => ({
  background: "none",
  boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  left: "49px",
  position: "absolute",
  top: "10px",
  maxHeight: "calc(100% - 32px)",
  overflowX: "hidden",
  overflowY: "scroll",
  width: width,
  zIndex: 4,
}));

export function fitMapToPolygonBounds(map, coordinates, options = {}) {
  if (!map || !coordinates || !Array.isArray(coordinates)) {
    return;
  }
  try {
    const boundingBox = bbox(turfPolygon(coordinates));

    map.fitBounds(
      [
        [boundingBox[0], boundingBox[1]], // Southwest
        [boundingBox[2], boundingBox[3]], // Northeast
      ],
      {
        padding: 20,
        maxZoom: 15,
        duration: 2000,
        ...options,
      }
    );
  } catch (error) {
    console.error("Error fitting map to polygon bounds:", error);
  }
}

const PolygonToolControl = ({ map }) => {
  const { isSm, isUpMd } = useBreakpoints();
  const [toolOpen, setToolOpen] = useState(false);

  const { paramsArray, polygon, setQueryParams, clearQueryParams } =
    useQueryParams();
  const { parameters, parameterCustomQuery } = useParameterInputs();

  const currentParams = useMemo(
    () => (paramsArray.length > 0 ? paramsArray : []),
    [paramsArray]
  );

  const defaultParams = currentParams.slice(
    0,
    DEFAULT_PARAMETERS_INPUT_VALUES.length
  );
  const customParams = currentParams.slice(
    DEFAULT_PARAMETERS_INPUT_VALUES.length
  );

  useEffect(() => {
    if (currentParams.length > 0 || polygon) {
      setToolOpen(true);
    }
  }, [currentParams.length, polygon]);

  const handleAccordionChange = (event, isExpanded) => {
    setToolOpen(isExpanded);
    if (isExpanded) {
      if (currentParams.length === 0) {
        setQueryParams(DEFAULT_PARAMETERS_INPUT_VALUES, null);
      }
    } else {
      clearQueryParams();
    }
  };

  const updateSearchParams = useCallback(
    (finalParams) => {
      setQueryParams(finalParams, polygon);
    },
    [setQueryParams, polygon]
  );

  const { startPolygonDraw, isDrawing, drawnPolygonArea } = usePolygonTool(
    map,
    polygon,
    (coordinates) => {
      setQueryParams(currentParams, coordinates);
    },
    toolOpen
  );

  const addCustomParam = useCallback(() => {
    const customParamData = parameterCustomQuery?.data || [];
    if (customParamData.length === 0) return;

    const selectedOptionNdxs = currentParams.map((param) => param.option_ndx);
    const newCustomOption = customParamData.find(
      (option) => !selectedOptionNdxs.includes(option.option_ndx)
    );

    if (!newCustomOption) return;

    const newCustomParam = {
      option_ndx: newCustomOption.option_ndx,
      weight: newCustomOption.wt_default ?? 1,
      input_type_ndx: 99,
    };

    const finalParams = [...currentParams, newCustomParam];
    updateSearchParams(finalParams);
  }, [currentParams, parameterCustomQuery, updateSearchParams]);

  const removeCustomParam = useCallback(
    (index) => {
      const finalParams = customParams.filter((_, i) => i !== index);
      const newFinalParams = [...defaultParams, ...finalParams];
      updateSearchParams(newFinalParams);
    },
    [defaultParams, customParams, updateSearchParams]
  );

  const {
    configurations,
    saveConfiguration,
    loadConfiguration,
    deleteConfiguration,
    renameConfiguration,
    updateConfiguration,
    clearAllConfigurations,
  } = useSavedConfigurations();

  const handleSave = useCallback(() => {
    saveConfiguration(null, currentParams, polygon);
  }, [currentParams, polygon, saveConfiguration]);

  const handleUpdateConfiguration = useCallback(
    (id) => {
      if (!Array.isArray(currentParams)) {
        console.error(
          "handleUpdateConfiguration called with invalid currentParams:",
          currentParams
        );
        return;
      }
      if (polygon !== null && typeof polygon !== "object") {
        console.error(
          "handleUpdateConfiguration called with invalid polygon:",
          polygon
        );
        return;
      }
      updateConfiguration(id, currentParams, polygon);
    },
    [currentParams, polygon, updateConfiguration]
  );

  const handleLoadConfiguration = useCallback(
    (id) => {
      const config = loadConfiguration(id);
      if (config) {
        const params =
          Array.isArray(config.params) && config.params.length > 0
            ? config.params
            : DEFAULT_PARAMETERS_INPUT_VALUES;
        const pol = config.polygon ? config.polygon : null;

        if (pol) {
          fitMapToPolygonBounds(map, pol);
        }

        setQueryParams(params, pol);
      }
    },
    [loadConfiguration, setQueryParams, map]
  );

  const handleDeleteConfiguration = useCallback(
    (id) => {
      deleteConfiguration(id);
    },
    [deleteConfiguration]
  );

  const handleRenameConfiguration = useCallback(
    (id, newLabel) => {
      renameConfiguration(id, newLabel);
    },
    [renameConfiguration]
  );

  const handleClearAllConfigurations = useCallback(() => {
    clearAllConfigurations();
  }, [clearAllConfigurations]);

  const isLoading =
    parameters.some(
      (paramQuery) => paramQuery.isLoading || paramQuery.isFetching
    ) ||
    parameterCustomQuery.isLoading ||
    parameterCustomQuery.isFetching;

  const hasError =
    parameters.some((paramQuery) => paramQuery.error) ||
    parameterCustomQuery.error;

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={2}>
        <CircularProgress />
      </Box>
    );
  }

  if (hasError) {
    return (
      <Box p={2}>
        <Typography color="error">
          Error loading parameters. Please try again later.
        </Typography>
      </Box>
    );
  }

  const calculatedWidth =
    isSm && !toolOpen ? "75px" : isSm && toolOpen ? "265px" : "315px";

  return (
    <Container width={calculatedWidth}>
      <Accordion expanded={toolOpen} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="polygon-tool-content"
          id="polygon-tool-header"
        >
          <Box alignItems="center" display="flex" columnGap={2}>
            <TuneIcon />
            {(isUpMd || toolOpen) && (
              <Typography variant="subtitle1">Risk Tool Controls</Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box p={2}>
            <ParameterInputs
              defaultParams={defaultParams}
              customParams={customParams}
              parameters={parameters}
              parameterCustomQuery={parameterCustomQuery}
              currentParams={currentParams}
              updateSearchParams={updateSearchParams}
              addCustomParam={addCustomParam}
              removeCustomParam={removeCustomParam}
            />

            <Divider sx={{ margin: [4, 3, 3, 3] }} />

            <PolygonControls
              isDrawing={isDrawing}
              polygon={polygon}
              startPolygonDraw={startPolygonDraw}
              currentParams={currentParams}
              drawnPolygonArea={drawnPolygonArea}
            />

            <Divider sx={{ margin: [4, 3, 3, 3] }} />

            <SavedConfigurationsList
              handleSave={handleSave}
              configurations={configurations}
              onLoad={handleLoadConfiguration}
              onDelete={handleDeleteConfiguration}
              onRename={handleRenameConfiguration}
              onUpdate={handleUpdateConfiguration}
              onClearAll={handleClearAllConfigurations}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default memo(PolygonToolControl);
