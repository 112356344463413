import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function PrintReportDialog({
  downloadCallback,
  setPrintReportDialogOpen,
  printReportDialogOpen,
}) {
  const [localTitle, setLocalTitle] = useState(""); // Local title state

  const handleClose = () => {
    setLocalTitle("");
    setPrintReportDialogOpen(false);
  };

  const handleDownload = () => {
    downloadCallback(localTitle);
    handleClose();
  };

  return (
    <Dialog
      open={printReportDialogOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Print Report</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please provide a title that will appear on the report.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Report Title"
          value={localTitle}
          onChange={(e) => setLocalTitle(e.target.value)} // Update local state
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDownload} color="primary">
          Download PDF
        </Button>
      </DialogActions>
    </Dialog>
  );
}
