import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button as MuiButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Button = styled(MuiButton)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const Wrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(6),
  textAlign: "center",
  background: "transparent",

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(10),
  },
}));

function Page404() {
  return (
    <Wrapper>
      <Helmet title="404 Error" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        404
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Page not found.
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        The page you are looking for might have been removed.
      </Typography>

      <Button component={Link} to="/" variant="contained" color="secondary">
        Return to home page
      </Button>
    </Wrapper>
  );
}

export default Page404;
