import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ResultsSummary from "./ResultsSummary";
import ResultsTabs from "./ResultsTabs";

const ResultsDialog = ({ open, onClose, results, submittedPolygonArea }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          maxHeight: "calc(100% - 64px)",
          width: "calc(100% - 64px)",
          maxWidth: "1200px",
        },
      }}
    >
      <DialogTitle>
        Results
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ResultsSummary
          data={results.data}
          submittedPolygonArea={submittedPolygonArea}
        />
        <ResultsTabs results={results} />
      </DialogContent>
    </Dialog>
  );
};

export default ResultsDialog;
