import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { colors } from "@mui/material";

const prepareMinMaxAvgValues = (str) => {
  if (str && str.length > 0) {
    const groups = str.split(", ");
    return (
      <Grid container spacing={0}>
        <Grid item xs={4} align="left">
          <Typography variant="h6">{groups[0].split(" ")[1]}</Typography>
          {groups[0].split(" ")[0]}
        </Grid>
        <Grid item xs={4} align="center">
          <Typography variant="h4">{groups[2].split(" ")[1]}</Typography>
          {groups[2].split(" ")[0]}
        </Grid>
        <Grid item xs={4} align="right">
          <Typography variant="h6">{groups[1].split(" ")[1]}</Typography>
          {groups[1].split(" ")[0]}
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};

const AqVulnTable = ({ data }) => {
  if (!data || data.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={400}
        bgcolor="#FCFDFE"
        borderRadius={1}
        sx={{ border: 1, borderColor: "divider" }}
      >
        <Typography variant="h6" color="textSecondary">
          No land use data available.
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={2}>
      {data.map((row, index) => (
        <Grid container spacing={4} key={index}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography variant="caption">{row.intersection_info}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle2"
              sx={{ color: colors.blueGrey[500] }}
              align="center"
              gutterBottom
            >
              {row.dtgw_heading}
            </Typography>
            <Box pl={10} pr={10}>
              {prepareMinMaxAvgValues(row.dtgw_values)}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle2"
              sx={{ color: colors.blueGrey[500] }}
              align="center"
              gutterBottom
            >
              {row.clayth_heading}
            </Typography>
            <Box pl={10} pr={10}>
              {prepareMinMaxAvgValues(row.clayth_values)}
            </Box>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default AqVulnTable;
