import React from "react";
import { CssBaseline, GlobalStyles } from "@mui/material";
import { styled } from "@mui/material/styles";

const globalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      html: {
        height: "100%",
      },
      body: {
        height: "100%",
        background: theme.palette.background.default,
      },
      "#root": {
        height: "100%",
      },
    })}
  />
);

const Root = styled("div")({
  maxWidth: "520px",
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100%",
});

const Auth = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      {globalStyles}
      {children}
    </Root>
  );
};

export default Auth;
