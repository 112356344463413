import React from "react";
import { ButtonGroup, Button } from "@mui/material";
import useBreakpoints from "../../../../hooks/useBreakpoints";

const WellStylesControl = ({ onChange, options, value }) => {
  const { isXs } = useBreakpoints();

  return (
    <ButtonGroup orientation={isXs ? "vertical" : "horizontal"}>
      {options.map((option) => {
        const active = value === option.value;
        return (
          <Button
            size={isXs ? "small" : "medium"}
            key={option.value}
            color={active ? "primary" : "inherit"}
            onClick={() => onChange(option.value)}
            variant={active ? "contained" : "outlined"}
          >
            {option.display}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default WellStylesControl;
