import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
} from "@mui/material";
import PlusIcon from "@mui/icons-material/Add";
import MinusIcon from "@mui/icons-material/Remove";
import useBreakpoints from "../../../../hooks/useBreakpoints";

const MultiSelect = ({
  filter,
  onChange = () => {},
  onSelectAll,
  onSelectNone,
}) => {
  const { isXs } = useBreakpoints();
  const { label, name, options = [], value = [] } = filter;

  return (
    <div style={{ maxWidth: "400px" }}>
      <Typography variant="subtitle1">{label}</Typography>
      <Box
        display="flex"
        flexDirection={isXs ? "column" : "row"}
        columnGap={8}
        mt={2}
      >
        <Button
          size="small"
          startIcon={<PlusIcon />}
          onClick={() => onSelectAll(name)}
          variant="outlined"
          sx={{ mb: isXs ? "4px" : 0 }}
        >
          Select All
        </Button>
        <Button
          size="small"
          startIcon={<MinusIcon />}
          onClick={() => onSelectNone(name)}
          variant="outlined"
        >
          Select None
        </Button>
      </Box>
      <Box
        sx={{
          maxHeight: isXs ? "35vh" : "auto",
          overflow: "auto",
        }}
      >
        <FormControl component="fieldset">
          <ul
            style={{
              paddingLeft: 0,
              listStyle: "none",
              columnCount: isXs ? 1 : 2,
            }}
          >
            {options.map((option) => (
              <li key={option.value}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={value.includes(option.value)}
                      onChange={onChange}
                      name={name}
                      value={option.value}
                    />
                  }
                  label={option.display}
                />
              </li>
            ))}
          </ul>
        </FormControl>
      </Box>
    </div>
  );
};

export default MultiSelect;
