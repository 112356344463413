import React, { useMemo, useState, memo } from "react";
import { styled } from "@mui/material/styles";
import { Typography, Divider, Pagination, Link } from "@mui/material";
import { titleize } from "inflected";
import { format as formatDate, isValid as isValidDate } from "date-fns";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { isNullOrUndef } from "chart.js/helpers";
import { formatBooleanTrueFalse } from "../../../utils";

// Styled components
const PopupWrap = styled("div")(({ height, width }) => ({
  height,
  overflowY: "scroll",
  width,
}));

const PopupTable = styled("table")({
  borderRadius: "5px",
  borderCollapse: "collapse",
  border: "1px solid #ccc",
  width: "100%",
});

const PopupRow = styled("tr")({
  borderRadius: "5px",
  "&:nth-of-type(even)": {
    backgroundColor: "#eee",
  },
});

const PopupCell = styled("td")({
  padding: "3px 6px",
  margin: 0,
});

const getUniqueFeatures = (array, comparatorProperty1, comparatorProperty2) => {
  const existingFeatureKeys = new Set();
  return array.filter((el) => {
    const key = `${el[comparatorProperty1]}-${el.layer[comparatorProperty2]}`;
    if (existingFeatureKeys.has(key)) return false;
    existingFeatureKeys.add(key);
    return true;
  });
};

const applyFormatting = (value, formatOptions) => {
  if (!formatOptions) return value;

  let formattedValue = value;

  if (formatOptions.decimalPrecision !== undefined && !isNaN(value)) {
    const precision = parseInt(formatOptions.decimalPrecision, 10);
    if (!isNaN(precision)) {
      formattedValue = parseFloat(formattedValue).toFixed(precision);
    }
  }

  formattedValue = formattedValue.toString();

  if (formatOptions.uppercase) formattedValue = formattedValue.toUpperCase();
  if (formatOptions.lowercase) formattedValue = formattedValue.toLowerCase();

  const parts = [];
  if (formatOptions.prefix) parts.unshift(formatOptions.prefix);
  parts.push(formattedValue);
  if (formatOptions.suffix) parts.push(formatOptions.suffix);

  formattedValue = parts.join("");

  if (formatOptions.dateFormat && isValidDate(new Date(value))) {
    formattedValue = formatDate(new Date(value), formatOptions.dateFormat);
  }

  return formattedValue;
};

const processTemplateString = (template, properties) => {
  return template.replace(/{(.*?)}/g, (_, key) => properties[key] || "");
};

// Main Popup component
const Popup = ({
  features,
  layers,
  currentUser,
  height = "200px",
  maxWidth = "380px",
  size = "medium",
}) => {
  const { isXs, isSm } = useBreakpoints();
  const [page, setPage] = useState(1);

  const uniqueFeatures = useMemo(
    () => getUniqueFeatures(features, "id", "id"),
    [features]
  );

  const feature = uniqueFeatures[page - 1] || null;

  const popupConfig = useMemo(() => {
    if (!feature) return {};
    const layer = layers.find((layer) => layer?.id === feature?.layer?.id);
    return layer?.lreProperties?.popup || {};
  }, [feature, layers]);

  const {
    excludeFields = [],
    excludePatterns = [],
    customLabels = {},
    sortOrder = [],
    reverseSortOrder = [],
    excludeAllExceptCustom = false,
    customFields = [],
  } = popupConfig;

  const adminOnlyFields = useMemo(() => {
    return currentUser?.isDeveloper || currentUser?.isAdmin
      ? []
      : popupConfig.adminOnlyFields ?? [];
  }, [currentUser, popupConfig]);

  const titleField = useMemo(() => {
    if (!feature) return null;
    const titleValue = feature?.properties?.[popupConfig.titleField] ?? null;

    return (
      <>
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          {titleize(feature?.layer?.source)}
        </Typography>
        {titleValue && (
          <>
            <Divider sx={{ my: 1, mx: 2 }} />
            <Typography sx={{ mb: 2 }} variant="h6">
              {titleValue}
            </Typography>
          </>
        )}
      </>
    );
  }, [feature, popupConfig]);

  // Logic to filter and sort popup data based on layers config
  const popupData = useMemo(() => {
    if (!feature) return [];

    const properties = feature?.properties;
    const fieldsToDisplay = [];

    Object.entries(properties).forEach(([key, value]) => {
      const matchesPattern = excludePatterns.some((pattern) =>
        key.includes(pattern)
      );
      const shouldExclude =
        excludeFields.includes(key) ||
        matchesPattern ||
        adminOnlyFields.includes(key) ||
        isNullOrUndef(value);

      const displayLabel = customLabels[key] || titleize(key);

      const fieldContent = { value, type: undefined };

      if (excludeAllExceptCustom) {
        if (
          customLabels[key] ||
          sortOrder.includes(key) ||
          reverseSortOrder.includes(key)
        ) {
          fieldsToDisplay.push([displayLabel, fieldContent]);
        }
      } else if (!shouldExclude) {
        fieldsToDisplay.push([displayLabel, fieldContent]);
      }
    });

    customFields.forEach((field) => {
      const { label, value: template, type, format } = field;
      const processedValue = processTemplateString(
        template,
        feature.properties
      );
      const formattedValue = applyFormatting(processedValue, format);

      fieldsToDisplay.push([
        label || titleize(field.key),
        { value: formattedValue, type },
      ]);
    });

    const sortedFields = fieldsToDisplay.sort((a, b) => {
      const [keyA] = a;
      const [keyB] = b;

      const indexA = sortOrder.indexOf(keyA);
      const indexB = sortOrder.indexOf(keyB);

      if (indexA !== -1 && indexB !== -1) return indexA - indexB;
      if (indexA !== -1) return -1;
      if (indexB !== -1) return 1;

      const reverseIndexA = reverseSortOrder.indexOf(keyA);
      const reverseIndexB = reverseSortOrder.indexOf(keyB);

      if (reverseIndexA !== -1 && reverseIndexB !== -1)
        return reverseIndexB - reverseIndexA;
      if (reverseIndexA !== -1) return 1;
      if (reverseIndexB !== -1) return -1;

      return 0;
    });

    return sortedFields;
  }, [
    feature,
    excludeFields,
    excludePatterns,
    customLabels,
    sortOrder,
    reverseSortOrder,
    excludeAllExceptCustom,
    adminOnlyFields,
    customFields,
  ]);

  const handlePageChange = (e, p) => setPage(p);

  if (!feature) return null;

  return (
    <>
      <PopupWrap
        height={isXs ? "120px" : height}
        width={isSm ? "205px" : maxWidth}
      >
        {titleField}
        <PopupTable>
          <tbody>
            {popupData.map(([label, { type, value }]) => (
              <PopupRow key={label}>
                <PopupCell>
                  <strong>{label}</strong>
                </PopupCell>
                <PopupCell>
                  {type === "url" ? (
                    <Link href={value} target="_blank" rel="noopener">
                      {value}
                    </Link>
                  ) : (
                    formatBooleanTrueFalse(value)
                  )}
                </PopupCell>
              </PopupRow>
            ))}
          </tbody>
        </PopupTable>
      </PopupWrap>
      <Pagination
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
        count={uniqueFeatures.length}
        size={isSm ? "small" : size}
        siblingCount={isSm ? 0 : 1}
        page={page}
        variant="outlined"
        shape="rounded"
        color="primary"
        onChange={handlePageChange}
      />
    </>
  );
};

export default memo(Popup);
