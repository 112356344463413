import React, { useCallback, useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";

import Popup from "../../Popup";
import useLayers from "../useLayers";
import { coordinatesGeocoder, MapLogger } from "./mapUtils";
import {
  BASEMAP_STYLES,
  WELLS_LABELS_LAYER_ID,
  WELLS_LAYER_ID,
} from "../../constants";
import debounce from "lodash.debounce";
import createTheme from "../../../../theme";
import { ThemeProvider } from "@mui/material/styles";
import RulerControl from "@mapbox-controls/ruler";
import "@mapbox-controls/ruler/src/index.css";
import ResetZoomControl from "../../controls/ResetZoomControl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { useApp } from "../../../../AppProvider";
import { createRoot } from "react-dom/client";

const mapLogger = new MapLogger({
  enabled: process.env.NODE_ENV === "development",
  prefix: "Public Map",
});

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const useMap = (ref, mapConfig, sources) => {
  const { currentUser } = useApp();

  const [map, setMap] = useState(null);
  const [activeBasemap, setActiveBasemap] = useState(
    mapConfig?.style || BASEMAP_STYLES[0].style
  );
  const [dataAdded, setDataAdded] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const controlsAdded = useRef(false);
  const popUpRef = useRef(
    new mapboxgl.Popup({
      maxWidth: "400px",
      offset: 15,
      focusAfterOpen: false,
    })
  );

  const { layers, setLayers } = useLayers();

  const initializeMap = useCallback(() => {
    if (ref.current && !map && !map?.loaded()) {
      const mapInstance = new mapboxgl.Map({
        container: ref.current,
        ...mapConfig,
      });

      mapInstance.on("load", () => {
        mapLogger.log("Map loaded");
        setMap(mapInstance);
      });

      // Cleanup map instance on unmount
      return () => {
        if (mapInstance) {
          mapInstance.remove();
          mapLogger.log("Map instance removed");
        }
      };
    }
  }, [ref, map, mapConfig]);

  /**
   * Handle Map Resizing with ResizeObserver
   */
  useEffect(() => {
    if (!map || !ref.current) return;

    const handleResize = debounce(() => {
      map.resize();
    }, 0);

    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
      handleResize.cancel();
    };
  }, [map, ref]);

  /**
   * Add Sources and Layers to the Map
   */
  const loadMapData = useCallback(() => {
    if (
      !map ||
      // !map.loaded() ||
      sources.length === 0 ||
      layers.length === 0 ||
      dataAdded
    ) {
      return;
    }

    // Add Sources
    sources.forEach((source) => {
      const { id, ...rest } = source;
      if (!map.getSource(id)) {
        map.addSource(id, rest);
      }
    });

    mapLogger.log("Sources added to map");

    const sortedLayers = [...layers].sort((a, b) => {
      const orderA =
        a?.lreProperties?.layerOrder !== undefined
          ? a?.lreProperties?.layerOrder
          : 0;
      const orderB =
        b?.lreProperties?.layerOrder !== undefined
          ? b?.lreProperties?.layerOrder
          : 0;
      return orderA - orderB;
    });
    sortedLayers.forEach((layer) => {
      if (!map.getLayer(layer.id)) {
        map.addLayer(layer);
      }
    });

    mapLogger.log("Layers added to map");
    setDataAdded(true);
  }, [map, sources, layers, dataAdded]);

  /**
   * Add Controls to the Map
   */
  const addMapControls = useCallback(() => {
    if (!map || controlsAdded.current) return;

    const addControl = (control, position) => {
      if (!map._controls || !map._controls.includes(control)) {
        map.addControl(control, position);
      }
    };

    const removeControl = (control) => {
      if (map._controls && map._controls.includes(control)) {
        map.removeControl(control);
      }
    };

    const controls = [
      { control: new mapboxgl.NavigationControl(), position: "top-left" },
      { control: new mapboxgl.FullscreenControl(), position: "top-left" },
      {
        control: new mapboxgl.GeolocateControl({
          positionOptions: { enableHighAccuracy: true },
          trackUserLocation: true,
          showUserHeading: true,
        }),
        position: "top-left",
      },
      { control: new ResetZoomControl(map), position: "top-left" },
      {
        control: new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          localGeocoder: coordinatesGeocoder,
          container: "geocoder-container",
          zoom: 16,
          mapboxgl: mapboxgl,
          reverseGeocode: true,
          placeholder: "Address/Coords",
        }),
        position: "top-right",
      },
      {
        control: new mapboxgl.ScaleControl({ unit: "imperial", maxWidth: 250 }),
        position: "bottom-left",
      },
      {
        control: new RulerControl({
          units: "yards",
          labelFormat: (n) => `${n.toFixed(2)} yd`,
        }),
        position: "bottom-left",
      },
    ];

    controls.forEach(({ control, position }) => addControl(control, position));

    mapLogger.log("Map controls added");
    controlsAdded.current = true;

    // Cleanup function to remove controls when unmounted or dependencies change
    return () => {
      controls.forEach(({ control }) => removeControl(control));
      mapLogger.log("Map controls removed");
    };
  }, [map]);

  /**
   * Add Event Handlers to the Map
   */
  const addMapEvents = useCallback(() => {
    if (!map || layers.length === 0 || !dataAdded || isMapLoaded) return;

    // Get layer IDs where popup is enabled (enabled by default)
    const cursorPointerLayerIds = layers
      .filter(
        (layer) =>
          layer.lreProperties?.disableCursorPointer !== true &&
          layer.lreProperties?.popup?.disabled !== true
      )
      .map((layer) => layer.id);

    let cursorOverFeature = false;
    // Cursor pointer on mouse enter/leave
    cursorPointerLayerIds.forEach((layerId) => {
      map.on("mousemove", layerId, () => {
        if (!cursorOverFeature) {
          map.getCanvas().style.cursor = "pointer";
          cursorOverFeature = true;
        }
      });

      map.on("mouseleave", layerId, () => {
        map.getCanvas().style.cursor = "";
        cursorOverFeature = false;
      });
    });

    // Get layer IDs where cursor pointer is enabled (enabled by default)
    const popupLayerIds = layers
      .filter((layer) => layer.lreProperties?.popup?.disabled !== true)
      .map((layer) => layer.id);

    // Handle Click Events for Popups
    const handleMapClick = (e) => {
      const features = map.queryRenderedFeatures(e.point);
      if (!features.length) return;

      const myFeatures = features.filter((feature) =>
        popupLayerIds.includes(feature.layer.id)
      );

      if (myFeatures.length === 0) return;

      // Determine Popup Coordinates
      // const firstFeature = myFeatures[0];
      const coordinates = e.lngLat;

      // Create Popup Content
      const popupNode = document.createElement("div");
      const root = createRoot(popupNode);

      root.render(
        <ThemeProvider theme={createTheme()}>
          <Popup
            layers={layers}
            features={myFeatures}
            currentUser={currentUser}
          />
        </ThemeProvider>
      );

      const popup = popUpRef.current
        .setLngLat(coordinates)
        .setDOMContent(popupNode)
        .addTo(map);

      popup.on("close", () => {
        document.getElementById("public-map").focus();
      });
    };

    map.on("click", handleMapClick);

    // Zoom-based visibility handling with config for layers with zoomThreshold
    let previousZoomLevel = map.getZoom();

    const handleZoom = () => {
      const zoomLevel = map.getZoom();
      let hasChanges = false;

      setLayers((prevLayers) => {
        const updatedLayers = prevLayers.map((layer) => {
          const { zoomThreshold } = layer?.lreProperties || {};
          if (!zoomThreshold) return layer; // No threshold, return unchanged

          const isCurrentlyVisible =
            map.getLayoutProperty(layer.id, "visibility") === "visible";
          let visibilityChanged = false;

          if (previousZoomLevel < zoomThreshold && zoomLevel >= zoomThreshold) {
            // Turn layer on if threshold is crossed upwards
            if (!isCurrentlyVisible) {
              map.setLayoutProperty(layer.id, "visibility", "visible");
              visibilityChanged = true;
            }
          } else if (
            previousZoomLevel >= zoomThreshold &&
            zoomLevel < zoomThreshold
          ) {
            // Turn layer off if threshold is crossed downwards
            if (isCurrentlyVisible) {
              map.setLayoutProperty(layer.id, "visibility", "none");
              visibilityChanged = true;
            }
          }

          if (visibilityChanged) {
            hasChanges = true;
            return {
              ...layer,
              layout: {
                ...layer.layout,
                visibility: visibilityChanged
                  ? zoomLevel >= zoomThreshold
                    ? "visible"
                    : "none"
                  : layer.layout.visibility,
              },
            };
          }
          return layer;
        });
        // Only return updated layers if changes were made
        return hasChanges ? updatedLayers : prevLayers;
      });

      previousZoomLevel = zoomLevel; // Always update the zoom level for the next check
    };

    map.on("zoom", handleZoom);

    mapLogger.log("Map events added");
    setIsMapLoaded(true);

    // Cleanup Event Listeners on Unmount or Dependencies Change
    return () => {
      cursorPointerLayerIds.forEach((layerId) => {
        map.off("mousemove", layerId);
        map.off("mouseleave", layerId);
      });
      map.off("click", handleMapClick);
      map.off("zoom", handleZoom);
      map.getCanvas().style.cursor = "";
    };
  }, [map, layers, dataAdded, isMapLoaded, currentUser, setLayers]);

  const updateLayerFilters = useCallback(
    (filterValues) => {
      if (!map) return;

      const mapFilterExpression = ["all"];

      Object.values(filterValues).forEach((filter) => {
        if (filter.type === "multi-select") {
          mapFilterExpression.push([
            "match",
            ["get", filter.layerFieldName],
            filter.value.length ? [...filter.value] : "",
            true,
            false,
          ]);
        } else if (filter.type === "boolean" && filter.value === true) {
          mapFilterExpression.push([
            "==",
            ["get", filter.layerFieldName],
            filter.value,
          ]);
        }
      });

      // Apply Filter to Relevant Layers
      map.setFilter(WELLS_LAYER_ID, mapFilterExpression);
      map.setFilter(WELLS_LABELS_LAYER_ID, mapFilterExpression);
      mapLogger.log("Filters updated");
    },
    [map]
  );

  const updateLayerStyles = useCallback(
    (layer) => {
      if (!map) return;

      Object.entries(layer.paint).forEach(([ruleName, ruleValue]) => {
        map.setPaintProperty(layer.layerId, ruleName, ruleValue);
      });

      setLayers((prevLayers) =>
        prevLayers.map((d) => {
          if (d.id !== layer.layerId) return d;
          return {
            ...d,
            paint: {
              ...d.paint,
              ...layer.paint,
            },
          };
        })
      );

      mapLogger.log(`Paint styles updated on the ${layer.layerId} layer`);
    },
    [map, setLayers]
  );

  const updateLayerVisibility = useCallback(
    ({ id, visible }) => {
      const groupedLayerIds = layers
        .filter(
          (layer) =>
            layer.lreProperties?.legend?.labelGroup === id || layer.id === id
        )
        .map(({ id }) => id);

      if (!map) return;

      const visibility = visible ? "visible" : "none";

      const updatedLayers = layers.map((layer) => {
        if (groupedLayerIds.includes(layer.id) && map.getLayer(layer.id)) {
          map.setLayoutProperty(layer.id, "visibility", visibility);
          return {
            ...layer,
            layout: {
              ...layer.layout,
              visibility,
            },
          };
        }
        return layer;
      });
      setLayers(updatedLayers);
      mapLogger.log(`Visibility set to ${visibility} for the ${id} layer(s)`);
    },
    [map, layers, setLayers]
  );

  const updateLayerOpacity = useCallback(
    ({ id, opacity }) => {
      if (!map) return;

      setLayers((prevLayers) =>
        prevLayers.map((layer) => {
          if (layer.id === id && map.getLayer(id)) {
            map.setPaintProperty(id, "fill-opacity", opacity);
            return {
              ...layer,
              paint: {
                ...layer.paint,
                "fill-opacity": opacity,
              },
            };
          }
          return layer;
        })
      );
      mapLogger.log(`Opacity set to ${opacity} for the ${id} layer`);
    },
    [map, setLayers]
  );

  const updateBasemap = useCallback(
    (style, filters) => {
      map?.setStyle(style.url);

      const styleLoadTimeout = setTimeout(() => {
        console.error("Style load timeout: Failed to load the map style.");
      }, 5000);

      map.once("idle", () => {
        clearTimeout(styleLoadTimeout);
        setActiveBasemap(style.style);
        setDataAdded(false);
        loadMapData();
        const checkDataAdded = setInterval(() => {
          if (dataAdded) {
            clearInterval(checkDataAdded);
            updateLayerFilters(filters);
          }
        }, 0);
      });
    },
    [dataAdded, loadMapData, map, updateLayerFilters]
  );

  /**
   * Initialize the Map on Mount
   */
  useEffect(() => {
    initializeMap();
  }, [initializeMap]);

  /**
   * Load Sources and Layers
   */
  useEffect(() => {
    loadMapData();
  }, [loadMapData]);

  /**
   * Add Controls
   */
  useEffect(() => {
    addMapControls();
  }, [addMapControls]);

  /**
   * Add Event Handlers
   */
  useEffect(() => {
    addMapEvents();
  }, [addMapEvents]);

  return {
    activeBasemap,
    basemaps: BASEMAP_STYLES,
    layers,
    map,
    sources,
    updateBasemap,
    updateLayerFilters,
    updateLayerStyles,
    updateLayerVisibility,
    updateLayerOpacity,
    isMapLoaded,
  };
};

export { useMap };
