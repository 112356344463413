import moment from "moment";
import * as htmlToImage from "html-to-image";
import jsPDF from "jspdf";

export const formatBooleanTrueFalse = (value) => {
  if (typeof value !== "boolean") {
    return value;
  }
  if (value === true) {
    return "Yes";
  }
  return "No";
};

export const downloadRef = (title, extension, ref) => {
  htmlToImage.toPng(ref.current).then(function (canvas) {
    const newTitle = `${title} ${dateFormatter(
      new Date(),
      "MM/DD/YYYY, h:mm A"
    )}.${extension}`;
    const pdf = new jsPDF("l", "pt", "a4", false);
    pdf.addImage(canvas, "PNG", 10, 10, 822, 0, undefined, false);
    pdf.save(
      `${newTitle} ${dateFormatter(new Date(), "MM/DD/YYYY, h:mm A")}.pdf`
    );
  });
};

export const lineColors = {
  red: "#e6194b",
  green: "#3cb44b",
  orange: "#f58231",
  blue: "#4363d8",
  purple: "#911eb4",
  cyan: "#2CF5F7",
  pink: "#f032e6",
  yellow: "#ffe119",
  neon: "#bcf60c",
  peach: "#fabebe",
  aqua: "#008080",
  lavender: "#e6beff",
  brown: "#9a6324",
  cream: "#fffac8",
  maroon: "#800000",
  turquoise: "#aaffc3",
  olive: "#808000",
  tan: "#ffd8b1",
  royalBlue: "#000075",
  lightBlue: "#74E0FF",
  gray: "#8D9093",
  black: "#000000",
  lightGray: "#eee",
  darkGray: "#222",
  white: "#fff",
};

export const dateFormatter = (date, format) => {
  return moment(date).format(format);
};

export const isTouchScreenDevice = () => {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints ||
    navigator.msMaxTouchPoints
  );
};

export const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
