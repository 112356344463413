import React from "react";
import { createRoot } from "react-dom/client";
import { Tooltip } from "@mui/material";
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM } from "../../constants";

class ResetZoomControl {
  constructor(map) {
    this.map = map;
    this._container = null;
    this._root = null;
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.map.flyTo({
      center: DEFAULT_MAP_CENTER,
      zoom: DEFAULT_MAP_ZOOM,
      padding: { bottom: 0 },
    });
  }

  onAdd() {
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

    const icon = (
      <button
        type="button"
        className="material-icons"
        style={{ verticalAlign: "middle", cursor: "pointer" }}
      >
        explore
      </button>
    );

    const tooltipContent = (
      <Tooltip title="Reset Map Extent" arrow>
        <span onClick={this.handleClick}>{icon}</span>
      </Tooltip>
    );

    this._root = createRoot(this._container);
    this._root.render(tooltipContent);

    return this._container;
  }

  onRemove() {
    if (this._root) {
      this._root.unmount();
    }
    if (this._container && this._container.parentNode) {
      this._container.parentNode.removeChild(this._container);
    }
    this._container = null;
    this._root = null;
  }
}

export default ResetZoomControl;
