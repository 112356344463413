import React, { useRef, useState } from "react";
import { ClickAwayListener, Fade, Button, Paper, Popper } from "@mui/material";
import { styled } from "@mui/material/styles";

const FilterAvatar = styled("span")(({ theme, $active }) => ({
  alignItems: "center",
  backgroundColor: "#ffffff",
  borderRadius: "50%",
  color: theme.palette.primary.main,
  display: "flex",
  fontSize: "12px!important",
  justifyContent: "center",
  height: theme.spacing(4),
  width: $active ? theme.spacing(10) : theme.spacing(4),
}));

const FilterContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const FilterControl = ({
  filter,
  label = filter?.label,
  children,
  width = "150px",
  appliedCount,
}) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  const activeFilterCount = filter?.value?.length;
  const totalFilterCount = filter?.options?.length;
  const active = activeFilterCount > 0 || appliedCount > 0;

  const handleClose = (event) => {
    if (buttonRef.current && buttonRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div style={{ height: "100%" }}>
      <Button
        sx={{
          width: width,
          height: "100%",
        }}
        color={active ? "primary" : "inherit"}
        variant={active ? "contained" : "outlined"}
        startIcon={
          active ? (
            <FilterAvatar $active={active}>
              {appliedCount
                ? appliedCount
                : `${activeFilterCount}/${totalFilterCount}`}
            </FilterAvatar>
          ) : undefined
        }
        onClick={() => {
          setOpen((s) => !s);
        }}
        ref={buttonRef}
      >
        {label}
      </Button>
      <Popper
        open={open}
        anchorEl={buttonRef?.current}
        placement="bottom-start"
        sx={{ zIndex: 4, border: "1px solid #ddd" }}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={350}>
              <FilterContainer>{children}</FilterContainer>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
};

export default FilterControl;
