import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";

import App from "./App";

const version = require("./vendor/version") || "1.0.0";
process.env.REACT_APP_VERSION = version;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1.0,
  release: `${process.env.REACT_APP_VERSION}`,
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(<App />);
