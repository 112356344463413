import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

const AutocompleteFilter = ({
  defaultValue,
  displayKey,
  error,
  label,
  name,
  options = [],
  onSelectionChange,
  valueKey,
  groupByKey,
  width = 350,
  disableClearable = false,
  size = "medium",
}) => {
  // Initialize the selected value based on defaultValue
  const [selectedOption, setSelectedOption] = useState(
    options.find((option) => option[valueKey] === defaultValue) || null
  );

  // Update the selected option if defaultValue or options change
  useEffect(() => {
    const newValue =
      options.find((option) => option[valueKey] === defaultValue) || null;
    setSelectedOption(newValue);
  }, [defaultValue, options, valueKey]);

  // Handle changes in the Autocomplete component
  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);
    // Call the callback with the selected value's valueKey or null
    if (onSelectionChange) {
      onSelectionChange(newValue ? newValue[valueKey] : null);
    }
  };

  return (
    <FormControl sx={{ width, minWidth: width, flexGrow: 1 }} error={!!error}>
      <Autocomplete
        size={size}
        disableClearable={disableClearable}
        id={name}
        options={options}
        getOptionLabel={(option) => option[displayKey]}
        groupBy={(option) => option[groupByKey].toString()}
        onChange={handleChange}
        value={selectedOption}
        isOptionEqualToValue={(option, value) =>
          option[valueKey] === (value ? value[valueKey] : null)
        }
        renderInput={(params) => (
          <TextField {...params} label={label} variant="outlined" />
        )}
      />
      {/* Hidden input to include the selected value in form submissions */}
      <input
        type="hidden"
        name={name}
        value={selectedOption ? selectedOption[valueKey] : ""}
      />
      {/* Display error message if any */}
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default AutocompleteFilter;
