import useFetchData from "../../../../../hooks/useFetchData";

const useParameterInputs = () => {
  const parameter1Query = useFetchData({
    endpoint: "controls-list-param/1",
    queryOptions: { staleTime: Infinity, cacheTime: Infinity },
  });

  const parameter2Query = useFetchData({
    endpoint: "controls-list-param/2",
    queryOptions: { staleTime: Infinity, cacheTime: Infinity },
  });

  const parameter3Query = useFetchData({
    endpoint: "controls-list-param/3",
    queryOptions: { staleTime: Infinity, cacheTime: Infinity },
  });

  const parameterCustomQuery = useFetchData({
    endpoint: "controls-list-param/custom",
    queryOptions: { staleTime: Infinity, cacheTime: Infinity },
  });

  const parameters = [parameter1Query, parameter2Query, parameter3Query];

  return {
    parameters,
    parameterCustomQuery,
  };
};

export default useParameterInputs;
