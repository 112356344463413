import { useEffect, useState } from "react";
import useFetchData from "../../../../hooks/useFetchData";

// Fetch a list of layers to add to the map
const useLayers = () => {
  const [layers, setLayers] = useState([]);

  const { data, error, isLoading } = useFetchData({
    endpoint: "public-map/layers",
    queryOptions: {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  });

  useEffect(() => {
    if (!!data?.length) {
      setLayers(data);
    }
  }, [data]);

  return {
    isLoading,
    error,
    layers,
    setLayers,
  };
};

export default useLayers;
