import React, { useRef, useState, useCallback, useEffect, memo } from "react";
import { useReactToPrint } from "react-to-print";
import { Box } from "@mui/material";
import SplitButton from "../../../components/SplitButton";
import PrintReportDialog from "./PrintReportDialog";
import PrintMapFormat from "./PrintMapFormat";

const SPLIT_BUTTON_OPTIONS = ["Print PDF", "Save PNG"];

const MapExportControl = ({ map }) => {
  const printRef = useRef();
  const [printReportDialogOpen, setPrintReportDialogOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [readyToPrint, setReadyToPrint] = useState(false);

  // Print PDF handler
  const handlePrintMapClick = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      setTitle("");
      setReadyToPrint(false);
    },
  });

  // Save PNG handler
  const handleSavePNG = useCallback(() => {
    const a = document.createElement("a");
    a.href = map.getCanvas().toDataURL();
    a.download = "map.png";
    document.body.appendChild(a);
    a.click();
  }, [map]);

  // Handle button selection (print or save)
  const handleSplitButtonClick = (index) => {
    if (index === 0) {
      setPrintReportDialogOpen(true);
    } else if (index === 1) {
      handleSavePNG();
    }
  };

  const handleDownload = (newTitle) => {
    setTitle(newTitle);
    setReadyToPrint(true);
  };

  useEffect(() => {
    if (readyToPrint && title) {
      handlePrintMapClick();
    }
  }, [readyToPrint, title, handlePrintMapClick]);

  return (
    <Box sx={{ pr: "16px" }}>
      <SplitButton
        options={SPLIT_BUTTON_OPTIONS}
        handleExportClick={handleSplitButtonClick}
      />

      <PrintReportDialog
        downloadCallback={handleDownload}
        setPrintReportDialogOpen={setPrintReportDialogOpen}
        printReportDialogOpen={printReportDialogOpen}
      />

      {map && readyToPrint && (
        <Box display="none">
          <div ref={printRef}>
            <PrintMapFormat
              title={title}
              mapImg={map.getCanvas().toDataURL("image/png")}
              map={map}
            />
          </div>
        </Box>
      )}
    </Box>
  );
};

export default memo(MapExportControl);
