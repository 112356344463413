import React from "react";
import { Box, Typography, Divider } from "@mui/material";

const getScoreBgColor = (score) => {
  score = parseFloat(score);
  if (score < 4) return "#28a745";
  if (score < 7) return "#ffc107";
  return "#dc3545";
};

const getScoreColor = (score) => {
  score = parseFloat(score);
  if (score < 4) return "#ffffff";
  if (score < 7) return "#343a40";
  return "#ffffff";
};

const ResultsSummary = ({ data, submittedPolygonArea }) => {
  if (!data || !data[0]) return null;
  const result = data[0];
  return (
    <Box>
      <Typography variant="subtitle1" align="center">
        {result.table_label}
      </Typography>
      <Box pb={1} textAlign="center">
        <Typography variant="caption" gutterBottom>
          1=LOW, 10=HIGH
        </Typography>
      </Box>
      <Divider />
      <Box my={2} display="flex" alignItems="baseline" justifyContent="center">
        <Box p={2} textAlign="center" width="150px">
          <Typography variant="h6">
            <Box
              p={1}
              mb={1}
              sx={{
                width: "100%",
                color: getScoreColor(result.user_scenario_score),
                backgroundColor: getScoreBgColor(result.user_scenario_score),
                borderRadius: "80px",
              }}
            >
              {result.user_scenario_score}
            </Box>
          </Typography>
          <div>Scenario Score</div>
        </Box>
        <Box p={2} textAlign="center" width="150px">
          <Typography variant="h6">
            <Box
              p={1}
              mb={1}
              sx={{
                width: "100%",
                color: getScoreColor(result.user_background_score),
                backgroundColor: getScoreBgColor(result.user_background_score),
                borderRadius: "80px",
              }}
            >
              {result.user_background_score}
            </Box>
          </Typography>
          <div>Background Score</div>
        </Box>
        <Box p={2} textAlign="center" width="150px">
          <Typography variant="h6">
            <Box
              p={1}
              mb={1}
              sx={{
                width: "100%",
                backgroundColor: "#efefef",
                borderRadius: "80px",
              }}
            >
              {result.delta}
            </Box>
          </Typography>
          <div>Change from Background</div>
        </Box>
      </Box>
      <Divider />
      <Box pt={1} textAlign="center">
        <Typography variant="caption">
          Query Area: {submittedPolygonArea} acres
        </Typography>
      </Box>
    </Box>
  );
};

export default ResultsSummary;
