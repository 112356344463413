import { useState, useCallback, useEffect } from "react";
import { INIT_FILTER_VALUES } from "../../constants";
import useFetchData from "../../../../hooks/useFetchData";

const initializeFilters = (data, prevState) => {
  return Object.keys(prevState).reduce((acc, key) => {
    const filterType = prevState[key]?.type;

    // Only update multi-select filters
    if (filterType === "multi-select") {
      acc[key] = {
        ...prevState[key],
        options: data?.[key] || [],
        value: data?.[key]?.map(({ value }) => value) || [],
      };
    } else {
      // Preserve the initial state for other filter types
      acc[key] = prevState[key];
    }

    return acc;
  }, {});
};

const useFilters = ({ onFilterChange }) => {
  const [filterValues, setFilterValues] = useState(INIT_FILTER_VALUES);

  const { data } = useFetchData({
    endpoint: "public-map/filters",
    queryOptions: {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  });

  useEffect(() => {
    if (data && typeof data === "object" && Object.keys(data).length > 0) {
      setFilterValues((prevState) => initializeFilters(data, prevState));
    }
  }, [data]);

  const handleSelectAll = useCallback(
    (name) => {
      setFilterValues((prevState) => {
        const updatedState = {
          ...prevState,
          [name]: {
            ...prevState[name],
            value: prevState[name]?.options.map(({ value }) => value) || [],
          },
        };
        onFilterChange(updatedState);
        return updatedState;
      });
    },
    [onFilterChange]
  );

  const handleSelectNone = useCallback(
    (name) => {
      setFilterValues((prevState) => {
        const updatedState = {
          ...prevState,
          [name]: {
            ...prevState[name],
            value: [],
          },
        };
        onFilterChange(updatedState);
        return updatedState;
      });
    },
    [onFilterChange]
  );

  const handleFilterValues = useCallback(
    (event) => {
      const { checked, name, value } = event.target;
      const type = filterValues[name]?.type;

      setFilterValues((prevState) => {
        let newValue;
        if (type === "multi-select") {
          newValue = prevState[name].value.includes(value)
            ? prevState[name].value.filter((v) => v !== value)
            : [...prevState[name].value, value];
        } else if (type === "boolean") {
          newValue = checked;
        } else {
          newValue = value;
        }

        const updatedState = {
          ...prevState,
          [name]: {
            ...prevState[name],
            value: newValue,
          },
        };

        onFilterChange(updatedState);
        return updatedState;
      });
    },
    [filterValues, onFilterChange]
  );

  return {
    filterValues,
    handleFilterValues,
    handleSelectAll,
    handleSelectNone,
  };
};

export default useFilters;
