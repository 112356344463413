import React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  List,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  Tooltip,
  Link,
} from "@mui/material";
import useBreakpoints from "../hooks/useBreakpoints";

const Wrapper = styled("div")(({ theme }) => ({
  padding: `calc(${theme.spacing(1)} / 4) ${theme.spacing(4)}`,
  background: theme.footer.background,
  position: "relative",
}));

const ListItem = styled(MuiListItem)(({ theme }) => ({
  display: "inline-block",
  width: "auto",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  color: "#ff0000",
  "&, &:hover, &:active": {
    color: "#ff0000",
  },
}));

const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  span: {
    color: theme.footer.color,
  },
}));

const BrandLogo = styled("img")(({ theme }) => ({
  width: "86px",
  height: "36px",
  marginTop: theme.spacing(3),
  marginRight: theme.spacing(4),
}));

function Footer() {
  const { isUpMd } = useBreakpoints();

  return (
    <Wrapper>
      <Grid container spacing={0} justifyContent="space-between">
        {isUpMd && (
          <Tooltip title="Built by LRE Water">
            <Link
              href="https://lrewater.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              <BrandLogo
                src="/static/img/branding/lrewater-logo-simple.svg"
                alt="LREWater.com"
              />
            </Link>
          </Tooltip>
        )}
        <Grid container item xs={12} md={5} justifyContent="flex-end">
          <List>
            <ListItem>
              <ListItemText
                primary={`© ${new Date().getFullYear()} - Leonard Rice Engineers, Inc.`}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
