import React from "react";

import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

const Root = styled("div")({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  minHeight: "100%",
});

function Loader({ size = 40 }) {
  return (
    <Root>
      <CircularProgress
        size={size}
        sx={{ zIndex: 1000 }}
        m={2}
        color="secondary"
      />
    </Root>
  );
}

export default Loader;
