import React, { memo, useMemo } from "react";
import { Box, Typography, Slider, IconButton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Close";
import AutocompleteFilter from "../../../../../components/inputs/AutocompleteFilter";
import useBreakpoints from "../../../../../hooks/useBreakpoints";

const sliderLabelFormat = (value) => `${(value * 100).toFixed(0)}%`;

const ParameterSet = ({
  label,
  index,
  param,
  options,
  onSelectChange,
  onSliderChange,
  isCustom = false,
  onRemove,
  selectedOptions = [],
}) => {
  const { isSm } = useBreakpoints();

  // Filter options to exclude already selected options except current
  const filteredOptions = useMemo(() => {
    return options.filter(
      (option) =>
        !selectedOptions.includes(option.option_ndx) ||
        option.option_ndx === param.option_ndx
    );
  }, [options, selectedOptions, param.option_ndx]);

  return (
    <Box mb={2}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={isCustom ? "space-between" : "flex-start"}
      >
        <Typography variant="h6" gutterBottom>
          {label}
        </Typography>
        {isCustom && (
          <IconButton onClick={() => onRemove(index)}>
            <RemoveIcon />
          </IconButton>
        )}
      </Box>
      <AutocompleteFilter
        name={`${isCustom ? "custom_" : ""}option_ndx_${index}`}
        options={filteredOptions}
        displayKey="option_desc"
        valueKey="option_ndx"
        groupByKey="input_type_ndx"
        defaultValue={param.option_ndx}
        onSelectionChange={(selected) => onSelectChange(index, selected)}
        width="100%"
        disableClearable
        size={isSm ? "small" : "medium"}
      />
      <Typography id={`slider-label-${index}`} gutterBottom>
        Weight: {sliderLabelFormat(param.weight)}
      </Typography>
      <Slider
        size={isSm ? "small" : "medium"}
        aria-labelledby={`slider-label-${index}`}
        value={param.weight}
        onChange={(event, newValue) => onSliderChange(index, newValue)}
        valueLabelFormat={sliderLabelFormat}
        valueLabelDisplay="auto"
        step={0.1}
        min={0}
        max={2}
      />
    </Box>
  );
};

export default memo(ParameterSet);
