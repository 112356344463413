import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import DetailsIcon from "@mui/icons-material/ListAlt";
import LandUseIcon from "@mui/icons-material/Terrain";
import ParcelsIcon from "@mui/icons-material/PhotoSizeSelectSmall";
import AqVulnIcon from "@mui/icons-material/Waves";
import DetailedResultsTable from "./DetailedResultsTable";
import LandUseTable from "./LandUseTable";
import ParcelsTable from "./ParcelsTable";
import AqVulnTable from "./AqVulnTable";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box pt={2}>{children}</Box>}
    </div>
  );
};

const ResultsTabs = ({
  results: {
    detailsData,
    detailsLandUseData,
    detailsParcelsData,
    detailsAqVulnData,
  },
}) => {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Box>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        aria-label="results table"
      >
        <Tab label="Detailed Results" icon={<DetailsIcon />} />
        <Tab label="Land Use" icon={<LandUseIcon />} />
        <Tab label="Parcels" icon={<ParcelsIcon />} />
        <Tab label="Aquifer Vulnerability" icon={<AqVulnIcon />} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <DetailedResultsTable data={detailsData} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <LandUseTable data={detailsLandUseData} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <ParcelsTable data={detailsParcelsData} />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <AqVulnTable data={detailsAqVulnData} />
      </TabPanel>
    </Box>
  );
};

export default ResultsTabs;
