import { useHistory, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

const useQueryParams = () => {
  const history = useHistory();
  const location = useLocation();

  const [paramsArray, setParamsArray] = useState([]);
  const [polygon, setPolygon] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // Parse the 'params' field as a JSON string
    const params = searchParams.get("params");
    const newParamsArray = params ? JSON.parse(params) : [];

    // Parse the 'polygon' field as a JSON string
    const polygonParam = searchParams.get("polygon");
    const newPolygon = polygonParam ? JSON.parse(polygonParam) : null;

    setParamsArray(newParamsArray);
    setPolygon(newPolygon);
  }, [location.search]);

  const setQueryParams = (paramsArray, polygon) => {
    const searchParams = new URLSearchParams();

    // Set 'params' as a JSON string
    if (Array.isArray(paramsArray)) {
      searchParams.set("params", JSON.stringify(paramsArray));
    }

    // Set 'polygon' as a JSON string
    if (polygon) {
      searchParams.set("polygon", JSON.stringify(polygon));
    }

    history.replace({ search: `?${searchParams.toString()}` });
  };

  const clearQueryParams = () => {
    history.replace({ search: "" });
  };

  return {
    paramsArray,
    polygon,
    setQueryParams,
    clearQueryParams,
  };
};

export default useQueryParams;
