import { useCallback, useMemo, useState, useEffect } from "react";
import { scaleOrdinal } from "d3-scale";
import { schemePaired } from "d3-scale-chromatic";
import { INIT_STYLE_VALUES } from "../../constants";

const buildScale = (values) => {
  const scale = scaleOrdinal(schemePaired);
  return values.reduce((acc, { display, value, color }) => {
    acc.push(display);
    acc.push(color || scale(value));
    return acc;
  }, []);
};

const getStyleWithPaint = (prevState, dataKey, field, data) => ({
  ...prevState[dataKey],
  paint: {
    "circle-color": ["match", ["get", field], ...buildScale(data), "#000000"],
  },
});

const useLayerStyles = ({ onLayerStyleChange, isMapLoaded, filterValues }) => {
  const [activeStyle, setActiveStyle] = useState(null);

  // Compute styleValues based on INIT_STYLE_VALUES and filterValues
  const styleValues = useMemo(() => {
    if (!filterValues || !isMapLoaded) return INIT_STYLE_VALUES;

    const newStyleValues = Object.keys(INIT_STYLE_VALUES).reduce((acc, key) => {
      const layerConfig = INIT_STYLE_VALUES[key];
      if (layerConfig.layerFieldName && filterValues[key]?.options) {
        acc[key] = getStyleWithPaint(
          INIT_STYLE_VALUES,
          key,
          layerConfig.layerFieldName,
          filterValues[key].options
        );
      } else {
        // For layers without dynamic filterValues, retain original config
        acc[key] = { ...layerConfig };
      }
      return acc;
    }, {});

    return newStyleValues;
  }, [filterValues, isMapLoaded]);

  // Set activeStyle based on defaultLoad or fallback to the first layer
  useEffect(() => {
    if (activeStyle || !isMapLoaded) return;
    const defaultLayer = Object.values(styleValues).find(
      (layer) => layer.defaultLoad
    );
    const fallbackLayer = Object.values(INIT_STYLE_VALUES)[0];
    const newActiveStyle = defaultLayer || fallbackLayer;

    setActiveStyle(newActiveStyle);
    onLayerStyleChange(newActiveStyle);
  }, [styleValues, isMapLoaded, activeStyle, onLayerStyleChange]);

  const handleActiveStyle = useCallback(
    (name) => {
      const selectedStyle = styleValues[name];
      if (selectedStyle) {
        setActiveStyle(selectedStyle);
        onLayerStyleChange(selectedStyle);
      }
    },
    [styleValues, onLayerStyleChange]
  );

  const styleOptions = useMemo(
    () =>
      Object.entries(styleValues).map(([key, value]) => ({
        display: value.name,
        value: key,
      })),
    [styleValues]
  );

  return {
    activeStyle,
    handleActiveStyle,
    styleOptions,
  };
};

export default useLayerStyles;
