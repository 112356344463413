import React from "react";
import Header from "./AppBar";
import Hero from "./Hero";
import Introduction from "./Introduction";
import Funding from "./Funding";
import Footer from "./Footer";

const Landing = () => {
  return (
    <>
      <Header />
      <Hero />
      <Introduction />
      <Funding />
      <Footer />
    </>
  );
};

export default Landing;
