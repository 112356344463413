import { useState, useEffect, useMemo } from "react";
import debounce from "lodash.debounce";

// Helper function to clamp longitude values within the geographic range.
// Longitude values must be within the range of -180 to 180 degrees.
// This function ensures values do not exceed these bounds, which can occur when the map view wraps around the globe.
// Clamping is useful for maintaining valid geographic coordinates and preventing errors in map displays or calculations.
function clampLongitude(lon) {
  return Math.max(-180, Math.min(180, lon));
}

const useMapDevTools = (map, isVisible) => {
  const [zoomLevel, setZoomLevel] = useState(0);
  const [centerCoordinates, setCenterCoordinates] = useState([0, 0]);
  const [mouseCoordinates, setMouseCoordinates] = useState([0, 0]);
  const [mapBounds, setMapBounds] = useState([
    [0, 0],
    [0, 0],
  ]);

  const debouncedSetZoomLevel = useMemo(
    () => debounce((zoom) => setZoomLevel(zoom), 100),
    []
  );
  const debouncedSetCenterCoordinates = useMemo(
    () => debounce((center) => setCenterCoordinates(center), 100),
    []
  );
  const debouncedSetMouseCoordinates = useMemo(
    () => debounce((mouse) => setMouseCoordinates(mouse), 100),
    []
  );
  const debouncedSetMapBounds = useMemo(
    () => debounce((bounds) => setMapBounds(bounds), 100),
    []
  );

  useEffect(() => {
    if (!map || !isVisible) return;

    const handleZoom = () => debouncedSetZoomLevel(map.getZoom());
    const handleMove = () =>
      debouncedSetCenterCoordinates(map.getCenter().toArray());
    const handleMouseMove = (e) =>
      debouncedSetMouseCoordinates([e.lngLat.lng, e.lngLat.lat]);
    const handleMoveEnd = () => {
      const bounds = map.getBounds().toArray(); // Get bounds as [[west, south], [east, north]]

      bounds[0][0] = clampLongitude(bounds[0][0]); // Clamping the west longitude
      bounds[1][0] = clampLongitude(bounds[1][0]); // Clamping the east longitude

      debouncedSetMapBounds(bounds);
    };

    map.on("zoom", handleZoom);
    map.on("move", handleMove);
    map.on("mousemove", handleMouseMove);
    map.on("moveend", handleMoveEnd);

    // Initial setup
    handleZoom();
    handleMove();
    handleMoveEnd();

    return () => {
      map.off("zoom", handleZoom);
      map.off("move", handleMove);
      map.off("mousemove", handleMouseMove);
      map.off("moveend", handleMoveEnd);
      debouncedSetZoomLevel.cancel();
      debouncedSetCenterCoordinates.cancel();
      debouncedSetMouseCoordinates.cancel();
      debouncedSetMapBounds.cancel();
    };
  }, [
    map,
    isVisible,
    debouncedSetZoomLevel,
    debouncedSetCenterCoordinates,
    debouncedSetMouseCoordinates,
    debouncedSetMapBounds,
  ]);

  return {
    zoomLevel,
    centerCoordinates,
    mouseCoordinates,
    mapBounds,
  };
};

export default useMapDevTools;
