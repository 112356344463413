import { useState, useEffect } from "react";

const useSavedConfigurations = () => {
  const [configurations, setConfigurations] = useState([]);

  useEffect(() => {
    // Load configurations from localStorage on mount
    const savedConfigs = JSON.parse(localStorage.getItem("savedConfigs")) || [];
    setConfigurations(savedConfigs);
  }, []);

  const saveConfigurationsToLocalStorage = (configs) => {
    localStorage.setItem("savedConfigs", JSON.stringify(configs));
  };

  const saveConfiguration = (label, params, polygon) => {
    const newConfig = {
      id: Date.now(), // Unique identifier
      label: label || `Scenario ${configurations.length + 1}`,
      params: Array.isArray(params) ? params : [],
      polygon: polygon || null,
    };
    const updatedConfigs = [...configurations, newConfig];
    setConfigurations(updatedConfigs);
    saveConfigurationsToLocalStorage(updatedConfigs);
  };

  const loadConfiguration = (id) => {
    const config = configurations.find((c) => c.id === id);
    return config;
  };

  const deleteConfiguration = (id) => {
    const updatedConfigs = configurations.filter((c) => c.id !== id);
    setConfigurations(updatedConfigs);
    saveConfigurationsToLocalStorage(updatedConfigs);
  };

  const renameConfiguration = (id, newLabel) => {
    const updatedConfigs = configurations.map((c) =>
      c.id === id ? { ...c, label: newLabel } : c
    );
    setConfigurations(updatedConfigs);
    saveConfigurationsToLocalStorage(updatedConfigs);
  };

  const updateConfiguration = (id, params, polygon) => {
    const updatedConfigs = configurations.map((c) =>
      c.id === id
        ? {
            ...c,
            params: Array.isArray(params) ? params : [],
            polygon: polygon || null,
          }
        : c
    );
    setConfigurations(updatedConfigs);
    saveConfigurationsToLocalStorage(updatedConfigs);
  };

  const clearAllConfigurations = () => {
    setConfigurations([]);
    localStorage.removeItem("savedConfigs");
  };

  return {
    configurations,
    saveConfiguration,
    loadConfiguration,
    deleteConfiguration,
    renameConfiguration,
    updateConfiguration,
    clearAllConfigurations,
  };
};

export default useSavedConfigurations;
