import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  authLayoutRoutes,
  dashboardLayoutRoutes,
  dashboardMaxContentLayoutRoutes,
  presentationLayoutRoutes,
} from "./index";

import Auth0ProviderWithHistory from "../auth/auth0-provider-with-history";
import {
  Dashboard as DashboardLayout,
  DashboardMaxContent as DashboardMaxContentLayout,
} from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import PresentationLayout from "../layouts/Presentation";
import Page404 from "../pages/auth/Page404";
import { AppProvider } from "../AppProvider";
import ScrollToTop from "../hooks/ScrollToTop";

const ChildRoutes = (Layout, routes) => {
  return routes.map(
    (
      { component: Component, guard, auth, children, crud, config = {}, path },
      index
    ) => {
      const Guard = guard || React.Fragment;
      if (crud) children = crud;
      const getRoute = ({ index, path, Guard, Component, config }) => {
        return (
          <Route
            key={index}
            path={path}
            exact
            render={(props) => (
              <Layout config={config}>
                <Guard>
                  <Component {...props} config={config} />
                </Guard>
              </Layout>
            )}
          />
        );
      };

      const output = [];
      const results = children
        ? children.map((element, index) => {
            const Guard = element.guard || React.Fragment;
            const Component = element.component || React.Fragment;

            return getRoute({
              index,
              path: element.path,
              Guard,
              Component,
              config: element.config || config,
            });
          })
        : null;

      if (results) {
        output.push(results);
      }

      if (Component) {
        output.push(
          getRoute({
            index,
            path,
            Guard,
            Component,
            config,
          })
        );
      }

      return output;
    }
  );
};

const Routes = () => {
  return (
    <Router>
      <Auth0ProviderWithHistory>
        <AppProvider>
          <ScrollToTop />
          <Switch>
            {ChildRoutes(DashboardLayout, dashboardLayoutRoutes)}
            {ChildRoutes(
              DashboardMaxContentLayout,
              dashboardMaxContentLayoutRoutes
            )}
            {ChildRoutes(AuthLayout, authLayoutRoutes)}
            {ChildRoutes(PresentationLayout, presentationLayoutRoutes)}
            <Route
              render={() => (
                <AuthLayout>
                  <Page404 />
                </AuthLayout>
              )}
            />
          </Switch>
        </AppProvider>
      </Auth0ProviderWithHistory>
    </Router>
  );
};

export default Routes;
