import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import Icon from "@mui/icons-material/Chat";

const RootContainer = styled("div")({
  backgroundColor: "#ffffff",
  boxShadow: "0 0 0 2px rgba(0,0,0,.1)",
  borderRadius: "4px",
  position: "absolute",
  zIndex: 1,
  bottom: 30,
  right: 10,
  display: "flex",
  flexDirection: "column",
});

const PopupControl = () => {
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <RootContainer>
        <Tooltip title="Toggle Map Popup">
          <IconButton
            size="small"
            color={isOpen ? "secondary" : "default"}
            onClick={handleToggle}
          >
            <Icon />
          </IconButton>
        </Tooltip>
      </RootContainer>
      {!isOpen && (
        <div
          dangerouslySetInnerHTML={{
            __html: "<style>.mapboxgl-popup { display: none; }</style>",
          }}
        />
      )}
    </>
  );
};

export default PopupControl;
