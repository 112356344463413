import React, { useState } from "react";
import {
  Box as MuiBox,
  Typography,
  IconButton,
  TextField,
  Divider,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import CheckIcon from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";

const ConfigBox = styled(MuiBox)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "8px",
});

const ClickableBox = styled(({ isEditing, ...rest }) => <MuiBox {...rest} />)(
  ({ theme, isEditing }) => ({
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    cursor: isEditing ? "default" : "pointer",
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: "4px",
    padding: "4px",
    transition: "border-color 0.3s, background-color 0.3s",
    "&:hover": {
      borderColor: isEditing
        ? theme.palette.grey[300]
        : theme.palette.grey[500],
      backgroundColor: isEditing ? "transparent" : theme.palette.grey[100],
    },
    [theme.breakpoints.up("md")]: {
      padding: "8px",
    },
  })
);

const IconButtonContainer = styled(MuiBox)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500,
}));

const StyledDivider = styled(Divider)({
  margin: "0 8px",
});

const SavedConfigurationItem = ({
  config,
  onLoad,
  onDelete,
  onRename,
  onUpdate,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [label, setLabel] = useState(config.label);

  const handleRename = () => {
    if (label.trim() === "") {
      setLabel(config.label);
    } else {
      onRename(config.id, label);
    }
    setIsEditing(false);
  };

  const handleUpdate = (e) => {
    e.stopPropagation();
    onUpdate(config.id);
  };

  const handleLoad = () => {
    if (!isEditing) {
      onLoad(config.id);
    }
  };

  return (
    <ConfigBox>
      <ClickableBox onClick={handleLoad} isEditing={isEditing}>
        {isEditing ? (
          <TextField
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onBlur={handleRename}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleRename();
              }
            }}
            autoFocus
            size="small"
            fullWidth
          />
        ) : (
          <StyledTypography>{config.label}</StyledTypography>
        )}
      </ClickableBox>

      <StyledDivider orientation="vertical" flexItem />

      <IconButtonContainer>
        <Tooltip title="Update Config">
          <IconButton
            aria-label="update config"
            onClick={handleUpdate}
            size="small"
          >
            <UpdateIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={isEditing ? "Apply Label" : "Edit Label"}>
          <IconButton
            aria-label={isEditing ? "apply label" : "edit"}
            onClick={(e) => {
              e.stopPropagation();
              if (isEditing) {
                handleRename();
              } else {
                setIsEditing(true);
              }
            }}
            size="small"
          >
            {isEditing ? (
              <CheckIcon fontSize="small" />
            ) : (
              <EditIcon fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Config">
          <IconButton
            aria-label="Delete"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(config.id);
            }}
            size="small"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </IconButtonContainer>
    </ConfigBox>
  );
};

export default SavedConfigurationItem;
