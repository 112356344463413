import MultiSelect from "../MultiSelect";
import Switch from "../Switch";

const filtersLookup = {
  "multi-select": MultiSelect,
  boolean: Switch,
};

const Filter = (props) => {
  const {
    filter: { type },
  } = props;
  const FilterComponent = filtersLookup[type];
  if (!FilterComponent) return null;
  return <FilterComponent {...props} />;
};

export default Filter;
