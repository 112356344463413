import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const columns = [
  { field: "input_category", headerName: "Category", flex: 2 },
  { field: "input_description", headerName: "Input Description", flex: 2 },
  {
    field: "wt",
    headerName: "Weight",
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueFormatter: (value) => `${parseInt(value * 100)}%`,
  },
  {
    field: "scenario_risk",
    headerName: "Scenario Score",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "baseline_risk",
    headerName: "Background Score",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "delta",
    headerName: "Change",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
];

const DetailedResultsTable = ({ data }) => {
  return (
    <Box height={400}>
      <DataGrid
        rows={data.map((row, index) => ({ id: index, ...row }))}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
      />
    </Box>
  );
};

export default DetailedResultsTable;
