import React, { memo } from "react";
import { List, Button, Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ParameterSet from "./ParameterSet";
import { DEFAULT_PARAMETERS_INPUT_VALUES } from "../constants";
import InfoDialog from "./InfoDialog";
import useBreakpoints from "../../../../../hooks/useBreakpoints";

const ParameterInputs = ({
  defaultParams,
  customParams,
  parameters,
  parameterCustomQuery,
  currentParams,
  updateSearchParams,
  addCustomParam,
  removeCustomParam,
}) => {
  const { isSm } = useBreakpoints();

  const handleDefaultSelectChange = (index, selectedOptionNdx) => {
    const newParamsArray = defaultParams.map((param, i) =>
      i === index ? { ...param, option_ndx: selectedOptionNdx } : param
    );
    const finalParams = [...newParamsArray, ...customParams];
    updateSearchParams(finalParams);
  };

  const handleDefaultSliderChange = (index, newValue) => {
    const newParamsArray = defaultParams.map((param, i) =>
      i === index ? { ...param, weight: newValue } : param
    );
    const finalParams = [...newParamsArray, ...customParams];
    updateSearchParams(finalParams);
  };

  const handleCustomSelectChange = (index, selectedOptionNdx) => {
    const newCustomParams = customParams.map((param, i) =>
      i === index ? { ...param, option_ndx: selectedOptionNdx } : param
    );
    const finalParams = [...defaultParams, ...newCustomParams];
    updateSearchParams(finalParams);
  };

  const handleCustomSliderChange = (index, newValue) => {
    const newCustomParams = customParams.map((param, i) =>
      i === index ? { ...param, weight: newValue } : param
    );
    const finalParams = [...defaultParams, ...newCustomParams];
    updateSearchParams(finalParams);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Parameter Inputs
        </Typography>

        <InfoDialog
          title="How to Use Parameter Inputs"
          content={
            <>
              <ol>
                <li>
                  Adjust the default parameters by selecting an option from the
                  dropdown and adjusting the sliders as needed.
                </li>
                <li>
                  <strong>Best Management Practices (BMP):</strong> Use the{" "}
                  <strong>"Add BMP"</strong> button to add a custom parameter.
                </li>
                <li>
                  You can remove or modify custom BMPs by adjusting the sliders
                  or using the delete option.
                </li>
              </ol>
              <Box mt={2}>
                <Typography variant="body2" color="textSecondary">
                  <strong>Note:</strong> You can bookmark or share URLs to open
                  the map, pre-select inputs, and draw polygons based on the
                  coordinates. The URL is updated instantly as you make changes.
                  Simply copy it from your browser's address bar and share it
                  with others to easily open the same scenario.
                </Typography>
              </Box>
            </>
          }
        />
      </Box>
      <List>
        {defaultParams.map((param, index) => (
          <ParameterSet
            key={`default-${index}`}
            label={
              DEFAULT_PARAMETERS_INPUT_VALUES?.[index]?.label ??
              `Parameter Set ${index + 1}`
            }
            index={index}
            param={param}
            options={parameters[index]?.data || []}
            onSelectChange={handleDefaultSelectChange}
            onSliderChange={handleDefaultSliderChange}
            selectedOptions={currentParams
              .filter((_, i) => i < defaultParams.length)
              .map((p) => p.option_ndx)}
          />
        ))}

        {customParams.map((customParam, index) => (
          <ParameterSet
            key={`custom-${index}`}
            label={`Custom BMP ${index + 1}`}
            index={index}
            param={customParam}
            options={parameterCustomQuery?.data || []}
            onSelectChange={handleCustomSelectChange}
            onSliderChange={handleCustomSliderChange}
            isCustom
            onRemove={removeCustomParam}
            selectedOptions={currentParams.map((p) => p.option_ndx)}
          />
        ))}

        <Box display="flex" justifyContent="center">
          <Button
            size={isSm ? "small" : "medium"}
            onClick={addCustomParam}
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Add BMP
          </Button>
        </Box>
      </List>
    </>
  );
};

export default memo(ParameterInputs);
