import { useQuery } from "react-query";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const useFetchData = ({
  endpoint,
  queryOptions = {},
  dependencies = [],
  checkAuth = true,
  method = "GET",
  payload = null,
}) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const fetchData = async () => {
    let headers = {};
    if (checkAuth && isAuthenticated) {
      const token = await getAccessTokenSilently();
      headers.Authorization = `Bearer ${token}`;
    }

    const options = { headers };
    if (method !== "GET" && payload) {
      options.method = method;
      options.data = payload;
    }

    try {
      const { data } = await axios({
        url: `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`,
        method,
        ...options,
      });
      return data;
    } catch (error) {
      throw new Error(`Error fetching data from ${endpoint}: ${error.message}`);
    }
  };

  const queryKey = [endpoint, method, checkAuth, ...dependencies];

  return useQuery(queryKey, fetchData, queryOptions);
};

export default useFetchData;
