import React, { useCallback, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { findRawRecords } from "./services/crudService";
import { CRUD_LOOKUP_TABLES } from "./constants";

export const AppContext = React.createContext();
export const useApp = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  // User
  const { user } = useAuth0();
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if (!user) return;

    const myUser = { ...user };
    const roles = myUser[`${process.env.REACT_APP_AUDIENCE}/roles`] || [];

    myUser.isDeveloper = roles.includes("Developer (rebuild)");
    myUser.isAdmin =
      roles.includes("Administrator (rebuild)") || myUser.isDeveloper;

    setCurrentUser(myUser);
  }, [user]);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    async function loadModels() {
      const modelsToLoad = CRUD_LOOKUP_TABLES;
      const token = await getAccessTokenSilently();
      const myLookupTableCache = {};

      for (const model of modelsToLoad) {
        try {
          myLookupTableCache[model] = await findRawRecords(model, token);
        } catch (err) {
          console.error(err);
        }
      }

      setLookupTableCache(myLookupTableCache);
    }

    if (user) {
      loadModels();
    }
  }, [user]); // eslint-disable-line

  // Toast
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("success");
  const [toastOptions, setToastOptions] = useState({});
  const [lookupTableCache, setLookupTableCache] = useState([]);

  const doToast = useCallback((severity, message, options) => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastOpen(true);
    setToastOptions(options);
  }, []);

  const toastValues = {
    toastOpen,
    toastMessage,
    toastSeverity,
    toastOptions,
    setToastOpen,
    setToastMessage,
    setToastSeverity,
    setToastOptions,
    doToast,
  };

  // Confirm Dialog
  const [confirmDialogKey, setConfirmDialogKey] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogPayload, setConfirmDialogPayload] = useState(null);
  const [confirmDialogCallback, setConfirmDialogCallback] = useState(
    async () => {}
  );

  const confirmationDialogValues = {
    confirmDialogKey,
    setConfirmDialogKey,
    confirmDialogOpen,
    setConfirmDialogOpen,
    confirmDialogPayload,
    setConfirmDialogPayload,
    confirmDialogCallback,
    setConfirmDialogCallback,
  };

  return (
    <AppContext.Provider
      value={{
        currentUser,
        lookupTableCache,
        ...toastValues,
        ...confirmationDialogValues,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
