import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const columns = [
  { field: "parcel_id", headerName: "Parcel Id", flex: 2 },
  { field: "parcel_owner", headerName: "Parcel Name", flex: 3 },
  { field: "parcel_address", headerName: "Parcel Address", flex: 3 },
  { field: "acres", headerName: "Acres", flex: 1 },
  { field: "cert_doc", headerName: "Cert Doc", flex: 2 },
];

const ParcelsTable = ({ data }) => {
  return (
    <Box height={400}>
      <DataGrid
        rows={data.map((row, index) => ({ id: index, ...row }))}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
      />
    </Box>
  );
};

export default ParcelsTable;
