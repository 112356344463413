import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

// For routes that can only be accessed by authorized users
const AuthVisibilityFilter = ({ children }) => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) return <React.Fragment />;

  return children;
};

export default AuthVisibilityFilter;
