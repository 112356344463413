import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { OpenInNew, Circle } from "@mui/icons-material";
import useBreakpoints from "../../../hooks/useBreakpoints";

// Styled Components
const Root = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

const SectionContainer = styled(Box)({
  maxWidth: 1200,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "96px 0 0",
  padding: 32,
});

const SectionTitle = styled(Typography)({
  textTransform: "uppercase",
  fontWeight: 800,
  letterSpacing: 6,
  textAlign: "center",
});

const HorizontalLine = styled("div")(({ theme }) => ({
  height: 3,
  flexGrow: 1,
  backgroundColor: "#8ed3f1",
  marginLeft: 32,
  [theme.breakpoints.down("sm")]: {
    marginLeft: 16,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  boxShadow: "2px 6px 16px rgba(0, 0, 0, 0.5)",
  transition: "box-shadow 0.3s ease",
  border: `5px solid ${theme.palette.secondary.main}`,
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    position: "relative",
    height: "100%",
  },
}));

const StyledCardContent = styled(CardContent)({
  padding: "32px 32px 16px",
  display: "flex",
  flexDirection: "column",
  borderBottom: "1px solid #e0e0e0",
});

const CardLogo = styled("img")(({ theme }) => ({
  objectFit: "contain",
  height: 200,
  maxWidth: "100%",
  marginBottom: 32,
  transition: "transform 0.3s ease",
  [theme.breakpoints.down("lg")]: {
    height: 150,
    marginBottom: 24,
  },
  [theme.breakpoints.down("md")]: {
    height: 100,
    marginBottom: 16,
  },
  [theme.breakpoints.up("md")]: {
    transformOrigin: "center",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
}));

const Logo = styled("img")(({ theme }) => ({
  height: 200,
  maxWidth: "100%",
  objectFit: "contain",
  transition: "transform 0.3s ease",
  [theme.breakpoints.up("md")]: {
    transformOrigin: "center",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  [theme.breakpoints.down("md")]: {
    maxHeight: 210,
    maxWidth: 385,
  },
  [theme.breakpoints.down("sm")]: {
    maxHeight: 120,
    maxWidth: 220,
  },
}));

function Funding() {
  const { isXs, isSm, isUpLg } = useBreakpoints();

  return (
    <Root>
      <SectionContainer>
        <Box display={"flex"} alignItems="center" width="100%" mb="32px">
          <SectionTitle
            color="primary"
            variant={isSm ? "h2" : "h1"}
            width={isXs ? 200 : isSm ? 250 : 450}
          >
            Brought to you by
          </SectionTitle>
          <HorizontalLine />
        </Box>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          padding={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            padding={2}
            mb={{ xs: 2, md: 2 }}
          >
            <SectionTitle color="primary" variant={isSm ? "h3" : "h2"}>
              Co-Sponsors
            </SectionTitle>
          </Grid>
          {[
            {
              src: "/static/img/landing/newmangrove-logo.svg",
              alt: "City of Newman Grove",
              href: "https://cityofnewmangrove.com/",
            },
            {
              src: "/static/img/landing/platte-center.png",
              alt: "Platte Center Nebraska",
              href: "https://plattecenter.com/water-protection-program",
            },
            {
              src: "/static/img/landing/lpnnrd.webp",
              alt: "Lower Platte North NRD",
              href: "https://lpnnrd.org/",
            },
          ].map((logo, index) => (
            <Grid
              item
              xs={12}
              md={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
              padding={2}
              key={index}
            >
              <a href={logo.href} target="_blank" rel="noopener noreferrer">
                <Logo src={logo.src} alt={logo.alt} />
              </a>
            </Grid>
          ))}
        </Grid>

        <Box padding={{ xs: 4, sm: 8, md: 12 }}>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            padding={2}
            mb={{ xs: 3, md: 5 }}
          >
            <SectionTitle color="primary" variant={isSm ? "h3" : "h2"}>
              Funding
            </SectionTitle>
          </Grid>
          <StyledCard>
            <StyledCardContent>
              <Box
                component="a"
                href="http://dee.ne.gov/NDEQProg.nsf/OnWeb/SWPGMain"
                target="_blank"
                rel="noreferrer noopener"
                textAlign="center"
              >
                <CardLogo
                  src="/static/img/landing/dee-logo-tag-dept.png"
                  alt="Nebraska Department of Environment and Energy"
                />
              </Box>
              <Typography
                variant={isUpLg ? "h4" : "h5"}
                color="textPrimary"
                sx={{ mt: 2, mb: 3 }}
              >
                Part of a <strong>joint source water protection effort</strong>{" "}
                co-sponsored by:
              </Typography>
              <List>
                {[
                  {
                    label: "City of Newman Grove",
                    href: "https://cityofnewmangrove.com/",
                  },
                  {
                    label: "Village of Platte Center",
                    href: "https://plattecenter.com/water-protection-program",
                  },
                  {
                    label:
                      "Lower Platte North Natural Resource District (LPNNRD)",
                    href: "https://lpnnrd.org/",
                  },
                ].map((sponsor, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <Circle color="primary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Button
                          variant="text"
                          component="a"
                          href={sponsor.href}
                          target="_blank"
                          rel="noreferrer noopener"
                          endIcon={<OpenInNew />}
                          color="secondary"
                        >
                          {sponsor.label}
                        </Button>
                      }
                    />
                  </ListItem>
                ))}
              </List>
              <Typography
                variant={isUpLg ? "h4" : "h5"}
                color="textPrimary"
                sx={{ mt: 2, mb: 3 }}
              >
                Funded with a{" "}
                <strong>Nebraska Department of Environment and Energy</strong>{" "}
                (NDEE) grant and matching funds from the{" "}
                <strong>co-sponsors</strong>.
              </Typography>
            </StyledCardContent>
            <CardActions sx={{ margin: 2 }}>
              <Button
                endIcon={<OpenInNew />}
                variant="contained"
                size="medium"
                color="secondary"
                component="a"
                target="_blank"
                rel="noreferrer noopener"
                href="http://dee.ne.gov/NDEQProg.nsf/OnWeb/SWPGMain"
                sx={{ width: 115, justifyContent: "start" }}
              >
                Visit
              </Button>
            </CardActions>
          </StyledCard>
        </Box>
      </SectionContainer>
    </Root>
  );
}

export default Funding;
