import axios from "axios";
import * as inflector from "inflected";

const getUrl = (modelName) => {
  const endpoint = inflector.dasherize(
    inflector.underscore(inflector.pluralize(modelName))
  );

  return `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`;
};

const getConfig = (token) => {
  return {
    headers: { Authorization: `Bearer ${token}` },
  };
};

export function findRawRecords(modelName, token) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(getUrl(modelName), getConfig(token));

      if (response.status === 200) {
        resolve(response.data);
      }

      reject(response.data);
    } catch (error) {
      reject(error);
    }
  });
}
