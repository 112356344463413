import React, { useState } from "react";
import {
  Badge,
  Button,
  CardHeader as MuiCardHeader,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Divider,
  Avatar,
  Box,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { ROUTES } from "../constants";
import useBreakpoints from "../hooks/useBreakpoints";

const UserDropdown = ({ children }) => {
  const { isXs } = useBreakpoints();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const { user, isLoading, isAuthenticated, logout, loginWithRedirect } =
    useAuth0();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  if (isLoading || !isAuthenticated) {
    return (
      <Button
        color="secondary"
        variant="contained"
        onClick={() =>
          loginWithRedirect({
            appState: { returnTo: ROUTES.LANDING },
          })
        }
      >
        {isXs ? "Log in" : "Log in to Dashboard"}
      </Button>
    );
  }

  return (
    <React.Fragment>
      {!children && (
        <Tooltip title="Account">
          <IconButton
            aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
            aria-haspopup="true"
            onClick={toggleMenu}
            color="inherit"
            size="large"
          >
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
              sx={{
                "& .MuiBadge-dot": {
                  backgroundColor: (theme) => theme.palette.success.main,
                  border: "1.5px solid white",
                  height: "8px",
                  width: "8px",
                  borderRadius: "50%",
                },
              }}
            >
              <Avatar
                sx={{ width: 24, height: 24 }}
                alt={user.name}
                src={user.picture}
              />
            </Badge>
          </IconButton>
        </Tooltip>
      )}
      {children && (
        <Tooltip title="Account">
          <div onClick={toggleMenu} style={{ cursor: "pointer" }}>
            {children}
          </div>
        </Tooltip>
      )}
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <Box sx={{ p: "6px 16px" }}>
          <MuiCardHeader
            avatar={
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
                sx={{
                  "& .MuiBadge-dot": {
                    backgroundColor: (theme) => theme.palette.success.main,
                    border: "1.5px solid white",
                    height: "12px",
                    width: "12px",
                    borderRadius: "50%",
                  },
                }}
              >
                <Avatar alt={user.name} src={user.picture} />
              </Badge>
            }
            title={user.name}
            subheader={user.email}
            sx={{ padding: "8px 0" }}
          />
        </Box>
        <Divider />
        <MenuItem
          onClick={() =>
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            })
          }
        >
          Log out
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default UserDropdown;
