import React, { memo } from "react";
import { List, Button, Typography, Box } from "@mui/material";
import SavedConfigurationItem from "./SavedConfigurationItem";
import SaveIcon from "@mui/icons-material/Save";
import InfoDialog from "./InfoDialog";
import useBreakpoints from "../../../../../hooks/useBreakpoints";

const SavedConfigurationsList = ({
  configurations,
  handleSave,
  onLoad,
  onDelete,
  onRename,
  onUpdate,
  onClearAll,
}) => {
  const { isSm } = useBreakpoints();

  return (
    <Box mt={4}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Saved Scenarios
        </Typography>

        <InfoDialog
          title="How to Use Saved Scenarios"
          content={
            <>
              <ol>
                <li>
                  Click <strong>"Save as New Scenario"</strong> to store your
                  current input selections and drawing in your browser’s local
                  storage.
                </li>
                <li>
                  Use the list below to quickly reload, update, rename, or
                  delete your saved scenarios.
                </li>
                <li>
                  Once you've loaded a saved scenario, click{" "}
                  <strong>"Run Scenario"</strong> to view the results.
                </li>
              </ol>
              <Box mt={2}>
                <Typography variant="body2" color="textSecondary">
                  <strong>Note:</strong> Your saved scenarios stored in your
                  browser’s local storage, which means they persist unless you
                  manually clear your browser's local storage or site data.
                  However, local storage is specific to your device and
                  browser—it won’t be accessible if you switch browsers or
                  devices unless you use the URL sharing feature.
                </Typography>
              </Box>
            </>
          }
        />
      </Box>

      <Button
        onClick={handleSave}
        variant="outlined"
        startIcon={<SaveIcon />}
        fullWidth
        size={isSm ? "small" : "medium"}
      >
        Save as New Scenario
      </Button>

      <List>
        {configurations.map((config) => (
          <SavedConfigurationItem
            key={config.id}
            config={config}
            onLoad={onLoad}
            onDelete={onDelete}
            onRename={onRename}
            onUpdate={onUpdate}
          />
        ))}
      </List>

      {configurations.length > 0 && (
        <Box display="flex" justifyContent="center">
          <Button
            size="small"
            onClick={onClearAll}
            color="error"
            variant="outlined"
          >
            Clear All Scenarios
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default memo(SavedConfigurationsList);
