import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ThemeProvider, CssBaseline } from "@mui/material";
import createTheme from "./theme";
import Routes from "./Routes/Routes";
import { APP_DETAILS, REACT_QUERY_CONFIG } from "./constants";

const queryClient = new QueryClient(REACT_QUERY_CONFIG);

function App() {
  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <Helmet
            titleTemplate={`%s | ${APP_DETAILS.SHORT_NAME}`}
            defaultTitle={`${APP_DETAILS.SHORT_NAME} Dashboard`}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={createTheme()}>
              <CssBaseline />
              <Routes />
            </ThemeProvider>
          </LocalizationProvider>
        </HelmetProvider>
      </QueryClientProvider>
    </React.Fragment>
  );
}

export default App;
