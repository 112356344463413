import React, { useRef, useState } from "react";
import { ClickAwayListener, Fade, Button, Paper, Popper } from "@mui/material";
import { styled } from "@mui/material/styles";

const FilterAvatar = styled("span")(({ theme, $active }) => ({
  alignItems: "center",
  backgroundColor: "#ffffff",
  borderRadius: "50%",
  color: theme.palette.primary.main,
  display: "flex",
  fontSize: "12px!important",
  justifyContent: "center",
  height: theme.spacing(4),
  width: $active ? theme.spacing(10) : theme.spacing(4),
}));

const FilterContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const TogglesControl = ({ filters, width = "150px", children }) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  const activeFilterCount = filters.reduce((count, filter) => {
    return filter.value === true ? count + 1 : count;
  }, 0);
  const totalFilterCount = filters.length;
  const active = activeFilterCount > 0;

  const handleClose = (event) => {
    if (buttonRef.current && buttonRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div style={{ height: "100%" }}>
      <Button
        sx={{
          width: width,
          height: "100%",
        }}
        color={active ? "primary" : "inherit"}
        variant={active ? "contained" : "outlined"}
        startIcon={
          active ? (
            <FilterAvatar $active={active}>
              {`${activeFilterCount}/${totalFilterCount}`}
            </FilterAvatar>
          ) : undefined
        }
        onClick={() => {
          setOpen((s) => !s);
        }}
        ref={buttonRef}
      >
        Toggles
      </Button>
      <Popper
        open={open}
        anchorEl={buttonRef?.current}
        placement="bottom-start"
        sx={{ zIndex: 4, border: "1px solid #ddd" }}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={350}>
              <FilterContainer>{children}</FilterContainer>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
};

export default TogglesControl;
